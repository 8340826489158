<template>
  <div>
    <template>
      <!--  面包屑  -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>基本资料</el-breadcrumb-item>
        <el-breadcrumb-item>仓库信息</el-breadcrumb-item>
      </el-breadcrumb>
      <!--  卡片视图区  -->
      <el-card>
        <el-row :gutter="30">
          <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
            <el-input placeholder="请输入内容" v-model="querInfo.w_name" clearable @clear="search" @keyup.native.enter="search">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="AddWarehouseSelect" icon="el-icon-document-add" v-if="is_add">添加</el-button>
          </el-col>
        </el-row>
        <el-table :data="warehouselist" border stripe v-loading="loading">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="仓库编号" prop="w_code" show-overflow-tooltip></el-table-column>
          <el-table-column label="仓库名称" prop="w_name" show-overflow-tooltip width="200px"></el-table-column>
          <el-table-column label="仓库省市区" prop="region" show-overflow-tooltip width="200px"></el-table-column>
          <el-table-column label="添加人" prop="add_uid"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <!-- 修改 -->
              <el-button type="primary" icon="el-icon-edit" size="mini" v-if="is_edit"
                         @click="WareEditDialog(scope.row.w_id)"></el-button>
              <!--删除-->
              <el-button type="danger" icon="el-icon-delete-solid" size="mini" v-if="is_edit"
                         @click="removeWareById(scope.row.w_id)"></el-button>

            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="querInfo.page"
            :page-sizes="[3,5,10,15]"
            :page-size="querInfo.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </el-card>
      <!--添加仓库的对话框-->
      <el-dialog
          title="添加仓库" :visible.sync="adddialogVisibleWareHouse" width="40%"
          @close="addWareHouseDialogClose">
        <el-form ref="addWareFormRef" :model="addWareHouseForm" label-width="150px"
                 :rules="addWareFormRules" >
          <el-form-item label="仓库名称" prop="w_name">
            <el-input v-model="addWareHouseForm.w_name"></el-input>
          </el-form-item>
          <el-form-item label="选择地址" prop="selectListarea">
            <el-cascader
                v-model="addWareHouseForm.selectListarea" clearable @change="addWareHouseChanged"
                :options="listarea" :props="cascaderProps"
                ></el-cascader>
          </el-form-item>

          <el-form-item label="仓库地址" prop="w_address">
            <el-input v-model="addWareHouseForm.w_address"></el-input>
          </el-form-item>
          <template v-for="(domain, index) in addWareHouseForm.warehousepartition">

            <el-form-item
                :label=" '仓库分区名称' + index" :key="domain.key"
                :prop="'warehousepartition.' + index + '.wp_name'"
                :rules="{required: true, message: '仓库分区名称不能为空', trigger: 'blur'}">
              <el-input v-model="domain.wp_name"></el-input>
            </el-form-item>
            <el-form-item
                :label="'仓库分区备注' + index" :key="domain.key1"
                :prop="'warehousepartition.' + index + '.wp_remark'"
                :rules="{required: true, message: '仓库分区备注不能为空', trigger: 'blur'}">
              <el-input v-model="domain.wp_remark"></el-input>
            </el-form-item>
            <el-form-item  :key="domain.key3">
              <el-button @click.prevent="removeDomain(domain)" class="removinput">删除</el-button>
            </el-form-item>

          </template>

        </el-form>
        <div class="addContacts">
          <el-button @click="addDomain">新增仓库分区</el-button>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="adddialogVisibleWareHouse = false">取 消</el-button>
          <el-button type="primary" @click="addWareHouse" :loading="addloading">确 定</el-button>
        </span>
      </el-dialog>

      <!--编辑仓库的对话框-->
      <el-dialog
          title="修改仓库"
          :visible.sync="editdialogVisibleWareHouse"
          width="40%"
          @close="editWareHouseDialogClose">
        <el-form ref="editWareFormRef" :model="WareEditForm" label-width="150px"
                 :rules="editWareFormRules" >
          <el-form-item label="仓库名称" prop="w_name">
            <el-input v-model="WareEditForm.w_name"></el-input>
          </el-form-item>
          <el-form-item label="选择地址" prop="editselectListarea">
            <el-cascader
                v-model="WareEditForm.editselectListarea" clearable @change="editWareHouseChanged"
                :options="listarea" :props="cascaderProps"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="仓库地址" prop="w_address">
            <el-input v-model="WareEditForm.w_address"></el-input>
          </el-form-item>
          <template v-for="(domain, index) in WareEditForm.warehousepartition">

            <el-form-item
                :label=" '仓库分区名称' + index" :key="domain.key"
                :prop="'warehousepartition.' + index + '.wp_name'"
                :rules="{required: true, message: '仓库分区名称不能为空', trigger: 'blur'}">
              <el-input v-model="domain.wp_name"></el-input>
            </el-form-item>
            <el-form-item
                :label="'仓库分区备注' + index" :key="domain.key1"
                :prop="'warehousepartition.' + index + '.wp_remark'"
                :rules="{required: true, message: '仓库分区备注不能为空', trigger: 'blur'}">
              <el-input v-model="domain.wp_remark"></el-input>
            </el-form-item>
            <el-form-item  :key="domain.key3">
              <el-button @click.prevent="removeDomain(domain)" class="removinput">删除</el-button>
            </el-form-item>

          </template>

        </el-form>
        <div class="addContacts">
          <el-button @click="editDomain">新增仓库分区</el-button>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editdialogVisibleWareHouse = false">取 消</el-button>
          <el-button type="primary" @click="editWareHouse" :loading="editloading">确 定</el-button>
        </span>
      </el-dialog>



    </template>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      editloading:false,
      //获取用户列表的参数对象
      querInfo: {
        page: 1,
        limit: 10,
        w_name:''
      },
      //页面权限
      customerJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      total:0,
      //仓库数据
      warehouselist:[],
      //控制添加仓库对话框的显示和隐藏
      adddialogVisibleWareHouse:false,
      //控制修改仓库对话框的显示和隐藏
      editdialogVisibleWareHouse:false,
      //添加仓库的表单数据对象
      addWareHouseForm:{
        //将要添加的仓库的名称
        w_name:'',
        //省ID
        w_provice:'',
        //市ID
        w_city:'',
        //区ID
        w_area:'',
        //仓库地址
        w_address:'',
        w_code:1,
        selectListarea:[],
        warehousepartition:[
          {
            wp_name:'',
            wp_remark:''
          }

        ]
      },
      //省市区的
      listarea:[],
      // selectListarea:[],
      // editselectListarea:[],
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'la_id',
        label: 'la_name',
        children: 'children'
      },
      //添加仓库表单的验证规则对象
      addWareFormRules:{
        w_name:[
          {required: true, message: '请输入仓库的名称', trigger: 'blur'}
        ],
        // w_address:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        selectListarea:[
          {required: true, message: '请选择地址', trigger: 'change'}
        ],
      },
      editWareFormRules:{
        w_name:[
          {required: true, message: '请输入仓库的名称', trigger: 'blur'}
        ],
        // w_address:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        editselectListarea:[
          {required: true, message: '请选择地址', trigger: 'change'}
        ],
      },
      //编辑返回获取的值
      WareEditForm:{
        editselectListarea:[]
      }

    }
  },
  created() {
    this.getWarehouseList()
    this.getWarehouseArea()
    this.getWarehouseJurisdiction()
  },
  activated(){

  },
  methods: {
    //获取权限接口
    async getWarehouseJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.WarehouseJurisdiction=res.data
      this.WarehouseJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    // 获取仓库
    async getWarehouseList(){
      this.loading=true
      const {data:res}=await this.$http.get('warehouse/getList',{params: this.querInfo})
      if (res.code!=200) return this.$message.error(res.msg)
      this.warehouselist = res.data.data
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getWarehouseList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getWarehouseList()
    },
    //查询
    search(){
      this.querInfo.page=1
      this.getWarehouseList()
    },
    //获取省市区
    async getWarehouseArea(){
      const {data:res}=await this.$http.get('listarea')
      if (res.code!=200) return this.$message.error(res.msg)
      this.listarea = res.data
    },
    //添加仓库
    AddWarehouseSelect(){
      this.adddialogVisibleWareHouse=true
      // this.getWarehouseArea()
    },
    //监视添加仓库对话框关闭的事件
    addWareHouseDialogClose(){
      this.addWareHouseForm.w_name='',
      this.addWareHouseForm.w_provice='',
      this.addWareHouseForm.w_city='',
      this.addWareHouseForm.w_area='',
      this.addWareHouseForm.w_address='',
      this.$refs.addWareFormRef.resetFields()
      this.selectListarea = [],
      this.addWareHouseForm.warehousepartition=[{
          wp_name:'',
          wp_remark:''
        }]
    },
    //监视编辑仓库对话框关闭的事件
    editWareHouseDialogClose(){
      this.$refs.editWareFormRef.resetFields()

    },
    // 添加 监听级联选择器 选中节点变化时触发
    addWareHouseChanged(){
      this.addWareHouseForm.w_provice=this.addWareHouseForm.selectListarea[0]
      this.addWareHouseForm.w_city=this.addWareHouseForm.selectListarea[1]
      this.addWareHouseForm.w_area=this.addWareHouseForm.selectListarea[2]
    },
    // 编辑 监听级联选择器 选中节点变化时触发
    editWareHouseChanged(){
      this.WareEditForm.w_provice=this.WareEditForm.editselectListarea[0]
      this.WareEditForm.w_city=this.WareEditForm.editselectListarea[1]
      this.WareEditForm.w_area=this.WareEditForm.editselectListarea[2]
    },
    //添加对话框 点击确定的事件
    addWareHouse(){
      this.$refs.addWareFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data:res} = await this.$http.post('warehouse/add',this.addWareHouseForm)
        this.addloading=false
        if (res.code!==200)return this.$message.error(res.msg)
        this.$message.success("添加成功")
        this.adddialogVisibleWareHouse=false
        this.getWarehouseList()
      })
    },
    //编辑仓库 chazhao
    async WareEditDialog(id){
      const {data:res} = await this.$http.get(`warehouse/find/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.WareEditForm = res.data
      this.WareEditForm.editselectListarea=res.data.region
      // this.getWarehouseArea()
      this.editdialogVisibleWareHouse=true
    },
    //修改提交数据
    editWareHouse(){
      this.$refs.editWareFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data:res} = await this.$http.post('warehouse/edit',this.WareEditForm)
        this.editloading=false
        if (res.code!==200)return this.$message.error(res.msg)
        this.$message.success("添加成功")
        this.editdialogVisibleWareHouse=false
        this.getWarehouseList()
      })
    },
    //删除仓库
    async removeWareById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`warehouse/del/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getWarehouseList()
    },
    addDomain() {
      this.addWareHouseForm.warehousepartition.push({
        wp_name:'',
        wp_remark:'',
        key: Date.now(),
        key1: Date.now()+1,
      });
    },
    editDomain() {
      this.WareEditForm.warehousepartition.push({
        wp_name:'',
        wp_remark:'',
        key: Date.now(),
        key1: Date.now()+1,
      });
    },
    removeDomain(item) {
      var index = this.addWareHouseForm.warehousepartition.indexOf(item)
      if (index !== -1) {
        this.addWareHouseForm.warehousepartition.splice(index, 1)
      }
    },
  }
}
</script>
